var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content-wrapper"},[_c('section',{staticClass:"content-header"},[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row mb-2"},[_vm._m(0),_c('div',{staticClass:"col-sm-6"},[_c('ol',{staticClass:"breadcrumb float-sm-right"},[_c('li',{staticClass:"breadcrumb-item"},[_c('router-link',{attrs:{"to":{ name: 'Home' }}},[_vm._v("Home")])],1),_c('li',{staticClass:"breadcrumb-item active"},[_vm._v("Custom Permission Detail")])])])])])]),(_vm.has_permission === true)?_c('section',{staticClass:"content"},[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header"},[_c('a',{staticClass:"btn btn-info",attrs:{"href":"javascript:void(0)"},on:{"click":function($event){_vm.showFilter = !_vm.showFilter}}},[_c('i',{staticClass:"fa fa-filter"}),_vm._v(" Filter ")]),_c('div',{staticClass:"card-tools"},[_c('router-link',{staticClass:"btn btn-success",attrs:{"to":{ name: 'CustomPermissionDetailCreate' }}},[_c('i',{staticClass:"fas fa-plus"}),_vm._v(" New ")])],1)]),(_vm.is_loading)?_c('div',{staticClass:"overlay"},[_c('i',{staticClass:"fas fa-2x fa-sync-alt fa-spin"})]):_vm._e(),_c('div',{staticClass:"card-body table-responsive-md p-0"},[_c('table',{staticClass:"table"},[_vm._m(1),_c('tbody',_vm._l((_vm.items.data),function(val,index){return _c('tr',[_c('td',[_vm._v(_vm._s(val.id))]),_c('td',[_vm._v(" "+_vm._s(val.custompermission_custom_permission_id.permission_name)+" ")]),_c('td',[_vm._v(_vm._s(val.routelist_route_id.route_name))]),_c('td',{staticClass:"text-right"},[_c('router-link',{staticClass:"btn btn-info btn-sm m-2px",attrs:{"to":{
                      name: 'CustomPermissionDetailEdit',
                      params: { id: val.id },
                    }}},[_c('i',{staticClass:"fas fa-pencil-alt"})])],1)])}),0)]),(_vm._.isEmpty(_vm.items.data))?_c('div',{staticClass:"col-12 p-4"},[_c('div',{staticClass:"alert alert-default-warning",attrs:{"role":"alert"}},[_vm._v(" No data found! ")])]):_vm._e()]),_c('div',{staticClass:"card-footer"},[(_vm.items.total > _vm.items.per_page)?_c('vue-pagination',{attrs:{"pagination":_vm.items},on:{"paginate":function($event){return _vm.getItems()}}}):_vm._e()],1)])])]):_vm._e(),(_vm.has_permission === false)?_c('section',{staticClass:"content"},[_vm._m(2)]):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-sm-6"},[_c('h1',[_vm._v("Custom Permission Detail")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',{staticStyle:{"width":"10px"}},[_vm._v("ID")]),_c('th',[_vm._v("Permission Name")]),_c('th',[_vm._v("Route")]),_c('th',{staticClass:"text-right"},[_vm._v("Action")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"alert alert-danger alert-dismissible"},[_c('h5',[_c('i',{staticClass:"icon fas fa-ban"}),_vm._v(" Permission denied!")])])
}]

export { render, staticRenderFns }