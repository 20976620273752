<template>
  <div class="content-wrapper">
    <section class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1>Custom Permission Detail</h1>
          </div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item">
                <router-link :to="{ name: 'Home' }">Home</router-link>
              </li>
              <li class="breadcrumb-item active">Custom Permission Detail</li>
            </ol>
          </div>
        </div>
      </div>
      <!-- /.container-fluid -->
    </section>

    <section class="content" v-if="has_permission === true">
      <div class="container-fluid">
        <div class="card">
          <div class="card-header">
            <a
              href="javascript:void(0)"
              class="btn btn-info"
              @click="showFilter = !showFilter"
              ><i class="fa fa-filter"></i> Filter
            </a>
            <div class="card-tools">
              <router-link
                :to="{ name: 'CustomPermissionDetailCreate' }"
                class="btn btn-success"
              >
                <i class="fas fa-plus"></i> New
              </router-link>
            </div>
          </div>
          <div class="overlay" v-if="is_loading">
            <i class="fas fa-2x fa-sync-alt fa-spin"></i>
          </div>

          <div class="card-body table-responsive-md p-0">
            <table class="table">
              <thead>
                <tr>
                  <th style="width: 10px">ID</th>
                  <th>Permission Name</th>
                  <th>Route</th>
                  <th class="text-right">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(val, index) in items.data">
                  <td>{{ val.id }}</td>
                  <td>
                    {{
                      val.custompermission_custom_permission_id.permission_name
                    }}
                  </td>
                  <td>{{ val.routelist_route_id.route_name }}</td>
                  <td class="text-right">
                    <router-link
                      :to="{
                        name: 'CustomPermissionDetailEdit',
                        params: { id: val.id },
                      }"
                      class="btn btn-info btn-sm m-2px"
                    >
                      <i class="fas fa-pencil-alt"></i>
                    </router-link>
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="col-12 p-4" v-if="_.isEmpty(items.data)">
              <div class="alert alert-default-warning" role="alert">
                No data found!
              </div>
            </div>
          </div>

          <div class="card-footer">
            <vue-pagination
              :pagination="items"
              v-if="items.total > items.per_page"
              @paginate="getItems()"
            />
          </div>
        </div>
      </div>
    </section>

    <section class="content" v-if="has_permission === false">
      <div class="alert alert-danger alert-dismissible">
        <h5><i class="icon fas fa-ban"></i> Permission denied!</h5>
      </div>
    </section>
  </div>
</template>

<script>
import VuePagination from "@/components/partials/PaginationComponent";
import SortIcons from "@/components/utils/SortIcons";
import { CustomRolesPermissionDetailAPI } from "@/services/api";

export default {
  name: "CustomRolesPermissionDetail",
  components: {
    VuePagination,
    SortIcons,
  },
  data: () => ({
    items: {
      total: 0,
      per_page: 1,
      from: 1,
      to: 0,
    },
    showFilter: false,
    filter: {},
    is_loading: false,
    has_permission: true
  }),
  methods: {
    getItems() {
      this.is_loading = true;
      let query = { ...this.filter, ...this.$route.query };

      CustomRolesPermissionDetailAPI.index(query)
        .then((items) => {
          this.items = items;
        })
        .catch((error) => {
          if (error.response.status === 403) {
            this.has_permission = false;
          }
        })
        .finally(() => {
          this.is_loading = false;
        });
    },
    resetFilter() {
      const query = Object.assign({}, this.$route.query);
      if (Object.keys(query).length > 0) {
        delete query.order_by;
        delete query.order_key;
        delete query.page;
        this.$router.replace({ query });
      }
      this.filter = {};
      this.getItems();
    },
    destroy: function (id, index) {
      // let _this = this;
      this.$swal
        .fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!",
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.is_loading = true;
            CustomRolesPermissionDetailAPI.destroy(id)
              .then((response) => {
                this.items.data.splice(index, 1);
                this.items.total--;
                this.$toaster.success(response.message);
              })
              .catch((error) => {
                console.log("Could not delete this:: ", error);
                this.$swal.fire("Failed!", "Failed to delete.", "error");
              })
              .finally(() => {
                this.is_loading = false;
              });
          }
        });
    }
  },
  mounted: function () {
    this.getItems();
    this.$root.$on("sort-data", () => {
      this.$root.$emit("show-icon");
      this.getItems();
    });
  },
};
</script>
